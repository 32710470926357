
var wow = new WOW(
  {
    boxClass:     'wow',      // animated element css class (default is wow)
    animateClass: 'animated is-animating', // animation css class (default is animated)
    offset:       0,          // distance to the element when triggering the animation (default is 0)
    mobile:       true,       // trigger animations on mobile devices (default is true)
    live:         true,       // act on asynchronously loaded content (default is true)
    callback:     function(box) {
      if( typeof(MotionUI) != 'undefined' && box.getAttribute("data-animate-in") !== null  ){


        MotionUI.animateIn(box, box.getAttribute("data-animate-in"));
      }
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
    }
  }
);